import * as React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import RectangleImage from '../../assets/images/rectangle.jpg'
import ContactForm from '../Home/ContactUs/ContactForm'
import { ThankYouDialog } from '../../components/shared/Dialogs'
import { useState } from 'react'

interface formData {}

const HomeIndex = () => {
  const [successfullyDialog, setSuccessfullyDialog] = React.useState<boolean>(false)

  // const value = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   contactNumber: '',
  //   code: '',
  //   country: '',
  //   description: '',
  // }

  // const [contactData, setContactData] = useState(value)

  const handleSubmit = (values: formData) => {
    setSuccessfullyDialog(true)
  }

  const handleClose = () => {
    setSuccessfullyDialog(false)
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Header maxWidth='false' />
        <Box id='contact-us' component='section' bgcolor='common.white' p={7.5}>
          <Container maxWidth='xl'>
            <Grid container spacing={7.5}>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <img src={RectangleImage} alt='contact-us' />
                  <Box
                    sx={{
                      position: 'absolute',
                      pr: 3.5,
                      py: 5,
                      maxWidth: 412,
                    }}
                  >
                    <Typography variant='h2' color='white'>
                      Have a Doubt? We would love to help you !!
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ContactForm setSuccessfullyDialog={setSuccessfullyDialog} />
              </Grid>
            </Grid>
          </Container>
          <ThankYouDialog open={successfullyDialog} onClose={handleClose} />
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  )
}

export default HomeIndex

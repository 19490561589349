import React, { useContext, useState, useEffect } from 'react'
import baseUrl from '../../../../config/baseUrl'
import {
  Avatar,
  Paper,
  Stack,
  IconButton,
  type IconButtonProps,
  styled,
  Button,
  Tooltip,
} from '@mui/material'
// import { useMessageContext } from '../../../../contexts'
// import Actions from './Actions'
import { useNavigate, useParams } from 'react-router-dom'
import Message from './Message'
import EditorButton from './Actions/EditorButton'
import CopyButton from './Actions/CopyButton'
import LikeButton from './Actions/LikeButton'
import DisLikeButton from './Actions/DisLikeButton'
import EditNoteIcon from '@mui/icons-material/EditNote'
import Stepper from './Actions/Stepper'
import { useSidebarContext } from '../../../../contexts'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import OldSidebarContex from '../../../../contexts/Old_SidebarContext'
import SContext, { SidebarProvider } from '../../../../contexts/SidebarContext'
import { string } from 'yup'

interface Props {
  question: string
  answer: string
  historyId: string
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
}))

const Messages = ({ question, answer, historyId }: Props) => {
  const param = useParams()
  const { id } = param
  // console.log('param', param)
  // const { messages, activeMessage } = useMessageContext()
  const [goToEditorBtnClicked, setGoToEditorBtnClicked] = useState(false)
  const { answerToLeftDrawer, setAnswerToLeftDrawer, openVerifier, setOpenVerifier } =
    useContext(SContext)

  const [alreadyLiked, setAlreadyLiked] = useState<boolean>(false)
  const {
    lastAskedQuestion,
    activeMessageContext,
    message,
    sendAnswerToLeftDrawer,
    setSendAnswerToLeftDrawer,
  } = useSidebarContext()

  console.log('lastAskedQuestion', lastAskedQuestion)
  const navigate = useNavigate()

  useEffect(() => {
    getFeedback()
    console.log('message', message)
    // console.log('I am near getFeedback();')
  }, [])

  // opens overifier automatically
  // useEffect(() => {
  //   answer && console.log(answer?.length)
  //   answer && answer?.length > 0 && setOpenVerifier(true)
  //   // this will open verifier
  // }, [answer])

  useEffect(() => {
    console.log('answerToLeftDrawer', answerToLeftDrawer)
    if (goToEditorBtnClicked && answerToLeftDrawer !== '') {
      setGoToEditorBtnClicked(false)
      navigate('/editor')
    }
  }, [answerToLeftDrawer])

  const getFeedback = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/like/getfeedback/${historyId}`)

      // console.log('api/like/getfeedback', data)

      if (!response.ok) {
        // console.log(data)
        return
      }
      const data = await response.json()
      data && console.log('feedback data', data)
      if (data.data.Like === true) {
        setAlreadyLiked((prevState) => (prevState = true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleFactCheck = () => {
    console.log('handleFactCheck')
    const objectToSend: {
      qs: string
      ans: string
      hId: string
      [key: string]: any
    } = {
      qs: question,
      ans: answer,
      hId: param?.id ?? '',
    }

    let queryString = ''
    for (const key in objectToSend) {
      const value = encodeURIComponent(objectToSend[key])
      queryString += `${key}=${value}&`
    }

    // Remove the trailing ampersand
    queryString = queryString.slice(0, -1)

    const url = '/verifier?' + queryString

    navigate(url)
  }

  const handleEditorClick = () => {
    const objectToSend: {
      qs: string
      ans: string
      hId: string
      [key: string]: any
    } = {
      qs: question,
      ans: answer,
      hId: param?.id ?? '',
    }

    let queryString = ''
    for (const key in objectToSend) {
      const value = encodeURIComponent(objectToSend[key])
      queryString += `${key}=${value}&`
    }

    // Remove the trailing ampersand
    queryString = queryString.slice(0, -1)

    const url = '/editor?' + queryString

    navigate(url)
    // setGoToEditorBtnClicked(true)
    // setAnswerToLeftDrawer(message)
  }

  const mapObj: Record<string, string> = {
    '\\n': '<br />',
    '{"result":"': '',
    '","total_tokens_consumed":2589}': '',
  }

  return (
    <Paper elevation={0} sx={{ p: 2, flexGrow: 1, position: 'relative' }}>
      <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Avatar variant='square'>LT</Avatar>

        {/* <Actions message={'1'} /> */}
        <Stack direction='row' spacing={2}>
          {/* <Stepper /> */}
          <Tooltip title='Verifier' placement='left'>
            <StyledIconButton aria-label='' onClick={handleFactCheck}>
              <FactCheckIcon />
            </StyledIconButton>
          </Tooltip>

          <Tooltip title='Editor' placement='top'>
            <StyledIconButton aria-label='' onClick={handleEditorClick}>
              <EditNoteIcon />
            </StyledIconButton>
          </Tooltip>

          <CopyButton message={answer} />

          {alreadyLiked ? (
            <LikeButton historyId={historyId} answer={answer} alreadyLiked={alreadyLiked} />
          ) : (
            <LikeButton historyId={historyId} answer={answer} alreadyLiked={false} />
          )}

          {!alreadyLiked ? (
            <DisLikeButton historyId={historyId} answer={answer} alreadyLiked={alreadyLiked} />
          ) : (
            <DisLikeButton historyId={historyId} answer={answer} alreadyLiked={alreadyLiked} />
          )}
        </Stack>
      </Stack>

      <Message message={answer} />
    </Paper>
  )
}

export default Messages

import React, { useState } from 'react'
// import ReactQuill from 'react-quill-new' 
import ReactQuill, { Quill } from 'react-quill';
// import EditorToolbar, { modules, formats } from './EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import './rightDrawer.css'
import baseUrl from '../../../../config/baseUrl'
import PropTypes from 'prop-types'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { marked } from 'marked';
import {
  Alert,
  Grid,
  Snackbar,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  // TextField,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

const EditablePdfReader = (props) => {
  const { ans, } = props
  const [file, setFile] = useState(null)

  const [editorHtml, setEditorHtml] = useState('<p>Type / Paste your <strong>Data</strong> here.</p>');

  const [state, setState] = React.useState({ value: null })

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  // Loading state
  const [isLoading, setIsLoading] = useState(false)

  const uId = sessionStorage.getItem('userId')

  const [userId, setUserId] = useState('')
  const [open, setOpen] = React.useState(false)

  const [anchor, setAnchor] = React.useState('right')

  const isSmallScreen = useMediaQuery('(max-width: 600px)')



  const Clipboard = Quill.import('modules/clipboard');

  class CustomClipboard extends Clipboard {
    onPaste(e) {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedHtml = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

      if (pastedHtml) {
        const quill = this.quill;
        const cursorPosition = quill.getSelection().index; // Get current cursor position
        quill.clipboard.dangerouslyPasteHTML(cursorPosition, marked(pastedHtml)); // Insert content at cursor
      }
    }
  }

  Quill.register('modules/clipboard', CustomClipboard);

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(editor.getHTML()); // Get HTML from the editor
  };



  React.useEffect(() => {
    setOpen(true)
    setFile(null)
    formatIncomingAns()
    setState({ ...state, value: ans })
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      localStorage.setItem('editorHtml', editorHtml)
      event.returnValue = '' // Required for the prompt to show
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  React.useEffect(() => {
    setUserId(uId)
  }, [uId])

  React.useEffect(() => {
    if (file === 'testing') {
      file && uploadFile()
    }
  }, [file])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const formatIncomingAns = () => {
    if (ans) {
      const html = marked(ans); // Convert markdown to HTML using marked
      setEditorHtml(html);
    }    // return ans
  }

  // const handleChange = (value) => {
  //   setState({ ...state, value: value })
  // }

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=900,height=700')

    // Write the quillContent to the new window
    printWindow.document.open()
    printWindow.document.write(`
      <html>
        <head>
          <title>LawTech</title>
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${editorHtml}
        </body>
      </html>
    `)
    printWindow.document.close()

    // Trigger print
    printWindow.print()
    printWindow.close()
  }

  const handleDownload = () => {
    const blob = new Blob([editorHtml], { type: 'text/html' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'editor.html'
    link.click()
    URL.revokeObjectURL(url)
  }

  const uploadFile = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('userid', userId)
    formData.append('editordocx', file)
    try {
      const response = await fetch(`${baseUrl}/api/editdocx/editdocxupload`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        setSnackbarMessage('Oops something went wrong while uploading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)

        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      if (data?.status) {
        setSnackbarMessage('File uploaded successfully')
        setSnackbarSeverity('success')
        setSnackbarOpen(true)
        readUploadedFile(data)
        setFile(null)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error uploading file:', error)
    }
  }

  const readUploadedFile = async (data) => {
    console.log('readUploadedFile data', data)
    try {
      const getReadFileResponse = await fetch(
        `${baseUrl}/api/editdocx/readEditDocx/${data.data.id}`,
      )
      if (!getReadFileResponse.ok) {
        setSnackbarMessage('Oops something went wrong while reading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        throw new Error('something went wrong while reading file...')
      }

      const getReadFileData = await getReadFileResponse.json()
      console.log('getReadFileData', getReadFileData)

      if (getReadFileData?.status) {
        getReadFileData && setEditorHtml(getReadFileData?.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        throw new Error('Something went wrong while reading file...')
      }
    } catch (error) {
      setSnackbarMessage(error)
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setIsLoading(false)
    }
  }

  EditablePdfReader.propTypes = {
    qs: PropTypes.string.isRequired,
    ans: PropTypes.string.isRequired,
    hId: PropTypes.string.isRequired,
  }


  return (
    <>
      <Box position='relative'>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              height: '100dvh',
              width: '100%',
              top: '50%',
              left: '45%',

              padding: '10px',
            }}
          >
            <CircularProgress color='success' />
          </Box>
        )}
        <React.Fragment key={anchor}>
          <Box
            open={true}
            onClose={!open}
            hideBackdrop={open}
            anchor={anchor}
            // border='6px solid #fb0039'
            PaperProps={{ sx: { width: '49%' } }}
            onClick={() => setAnchor('right')}
          >
            <div>
              <div
                style={{
                  backgroundColor: '#fbd439',
                  color: '#093f6b',
                }}
              >
                {/* <h3 style={{ textAlign: 'center' }}>LawTech Editor at you hand...</h3> */}
              </div>
              <Grid container justifyContent='flex-end' marginBottom='10px'>
                <Button // button to upload file
                  startIcon={<LocalPrintshopIcon />}
                  variant='contained'
                  sx={{
                    height: isSmallScreen ? '30px' : '40px',
                    width: isSmallScreen ? '100px' : '140px',
                    marginRight: '10px',
                    marginTop: isSmallScreen ? '0px' : '10px',
                    color: 'black',
                    fontSize: isSmallScreen ? '10px' : '',
                  }}
                  onClick={handlePrint}
                >
                  Print
                </Button>

                {/* Download button to save file in pdf or docs format */}
                <Button // button to upload file
                  startIcon={<DownloadIcon />}
                  variant='contained'
                  sx={{
                    height: isSmallScreen ? '30px' : '40px',
                    width: isSmallScreen ? '100px' : '140px',
                    marginRight: '10px',
                    marginTop: isSmallScreen ? '0px' : '10px',
                    color: 'black',
                    fontSize: isSmallScreen ? '10px' : '',
                  }}
                  onClick={handleDownload}
                >
                  Download
                </Button>


              </Grid>

              <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                {/* <EditorToolbar /> */}
                {/* <ReactQuill
                  theme='snow'
                  style={{ height: '500px', overflowY: 'scroll' }}
                  value={state.value}
                  onChange={handleChange}
                  placeholder={'Write something awesome...'}
                  modules={modules}
                  formats={formats}
                /> */}

                <ReactQuill
                  value={editorHtml}
                  onChange={handleEditorChange}
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'align': [] }],
                      ['link', 'image'],
                      ['clean'],
                    ],
                    clipboard: { matchVisual: false }, // Disable Quill's visual matching for pasting
                  }}
                />

              </div>
            </div>
          </Box>
        </React.Fragment>
      </Box>

      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default EditablePdfReader

import React, { useState, useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {


    Pagination.propTypes = {
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
    };


    const [inputPage, setInputPage] = useState('');

    useEffect(() => {
        setInputPage(currentPage.toString());
    }, [currentPage]);

    const handlePreviousPage = () => {
        onPageChange(currentPage - 1);
    };

    const handleNextPage = () => {
        onPageChange(currentPage + 1);
    };

    const handleInputChange = e => {
        setInputPage(e.target.value);
    };

    const handleInputSubmit = e => {
        e.preventDefault();
        const pageNumber = parseInt(inputPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        }
    };

    // const sidebarWidth = '200px';


    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // position: 'fixed',
                // right: 0,

                // bottom: 5,
                width: '84%',
                // maxWidth: '100vw',
                margin: '1px auto'
            }}
        >
            <IconButton
                onClick={handlePreviousPage}
                variant="text"
                disabled={currentPage === 1}
            >
                <ArrowBackIosIcon
                    size="small"
                    style={{ fontSize: '16px', color: 'var(--white-color)' }}
                />
            </IconButton>
            <form
                onSubmit={handleInputSubmit}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <input
                    // type="number"
                    value={inputPage}
                    onChange={handleInputChange}
                    min={1}
                    max={totalPages}
                    style={{
                        height: '23px',
                        width: '35px',
                        textAlign: 'center',
                        // marginRight: '10px',
                        // marginLeft: '10px',
                        backgroundColor: 'var(--primary-color)',
                        color: 'var(--black-color)',
                        outline: 'none',
                        border: 'none',
                        borderRadius: '2px'
                    }}
                />
            </form>
            <Typography style={{ fontSize: '14px' }}>
                {' of '}
                {totalPages}
            </Typography>
            <IconButton
                // size="small"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{ marginLeft: '10px' }}
            >
                <ArrowForwardIosIcon
                    size="small"
                    style={{ fontSize: '16px', color: 'var(--white-color)' }}
                />
            </IconButton>
        </div>
    );
};

export default Pagination;


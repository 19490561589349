import React, { useContext } from 'react'
import { Button, type ButtonProps, styled, Typography } from '@mui/material'
import { PlusIcon } from '../../../../components/Icons'
import { useNavigate } from 'react-router-dom'
import SContext from '../../../../contexts/SidebarContext'
import { useSidebarContext } from '../../../../contexts'
import baseUrl from '../../../../config/baseUrl'

interface Props {
  open: boolean
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface ButtonTypes extends ButtonProps {
  open: boolean
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ButtonTypes>(({ theme, open }) => ({
  width: open ? 32 : 126,
  minWidth: 32,
  height: 32,
  padding: '4px 10px',
  borderRadius: 2,
  transition: theme.transitions.create(['width', 'opacity']),
  opacity: 1,
  '& .MuiTypography-body2': {
    opacity: 1,
  },
  ...(open && {
    '& > span': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiTypography-body2': {
      opacity: 0,
      width: 0,
    },
  }),
}))

const NewChatButton = ({ open, setSearchOpen }: Props) => {
  const {
    selectedTab,
    setSelectedTab,
    setAllHistory,
    setThreadId,
    threadId,
    globalTokenConsumed,
    setGlobalTokenConsumed,
  } = useSidebarContext()

  const { setNewChatClicked, setUploadedFileName, setIsRefFileUploaded } = useContext(SContext)

  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  const createChat = (randomObject: any) => {
    return fetch(`${baseUrl}/api/users/StoreHistory1`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchTitle: randomObject.searchTitle,
        searchResult: randomObject.searchResult,
        UserId: userId,
        ThreadId: threadId,
        Token: globalTokenConsumed,
      }),
    })
  }

  const generateRandomString = (length: any) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }
    return result
  }
  const generateRandomObject = () => {
    const randomSearchTitle = generateRandomString(10)
    const randomSearchResult = generateRandomString(20)

    const randomObject = {
      searchTitle: randomSearchTitle,
      searchResult: randomSearchResult,
    }

    return randomObject
  }
  const newChat = () => {
    setIsRefFileUploaded(false)
    setUploadedFileName('')

    setNewChatClicked(true)
    setSelectedTab(1)
    setAllHistory([])
    setThreadId(null)
    setSearchOpen(false)
    setIsRefFileUploaded(false)
    setUploadedFileName('')



    navigate('/NewChat')
  }
  return (
    <StyledButton open={open} onClick={newChat} variant='contained' startIcon={<PlusIcon />}>
      <Typography variant='body2'>New Chat</Typography>
    </StyledButton>
  )
}

export default NewChatButton

import React, { useState } from 'react'
import { Avatar, Paper, Stack, Typography, type TypographyProps, styled } from '@mui/material'
import { useSidebarContext } from '../../../../contexts'

const StyledLink = styled(Typography)<TypographyProps>(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.common.lightBlue,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

interface Props {
  category: string | null

  aiStorage: any[] // Assuming aiStorage is an array of objects
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
}

const NewMessage = ({ category, aiStorage, setAiStorage }: Props) => {
  let content = null

  const [question, setQuestion] = useState('')

  // alert('quesiton outside prompt ' + question)

  const { sendData, setMessage, setInputMessage } = useSidebarContext()

  const handleClick = () => {
    // aiResponse()
    // sendData()
    setMessage('')
  }

  if (category === 'Civil Cases') {
    content = (
      <>
        <StyledLink
          onClick={() => {
            // handleClick()
            setInputMessage('How to deal with a Civil Case?')
            // setMessage('How to deal with a Civil Case?')
          }}
        >
          How to deal with a Civil Case?
        </StyledLink>
        <StyledLink
          onClick={() => {
            // handleClick()
            setInputMessage('What are the Do’s and Don’ts in Civil Cases?')
            // setMessage('What are the Do’s and Don’ts in Civil Cases?')
          }}
        >
          What are the Do’s and Don’ts in Civil Cases?
        </StyledLink>
      </>
    )
  } else if (category === 'Criminal Cases') {
    content = (
      <>
        <StyledLink
          onClick={() => {
            // handleClick()
            setQuestion('How to deal with a Criminal Case?')
            // setMessage('How to deal with a Criminal Case?')
          }}
        >
          How to deal with a Criminal Case?
        </StyledLink>
        <StyledLink
          onClick={() => {
            // handleClick()
            setQuestion('What are the Do’s and Don’ts in Criminal Cases?')
            // setMessage('What are the Do’s and Don’ts in Criminal Cases?')
          }}
        >
          What are the Do’s and Don’ts in Criminal Cases?
        </StyledLink>
      </>
    )
  } else if (category === 'Bankruptcy Cases') {
    content = (
      <>
        <StyledLink
          onClick={() => {
            // handleClick()

            setInputMessage('How to deal with a Bankruptcy Case?')
            // setMessage('How to deal with a Bankruptcy Case?')
          }}
        >
          How to deal with a Bankruptcy Case?
        </StyledLink>
        <StyledLink
          onClick={() => {
            // handleClick()
            setInputMessage('What are the Do’s and Don’ts in Bankruptcy Cases?')
            // setMessage('What are the Do’s and Don’ts in Bankruptcy Cases?')
          }}
        >
          What are the Do’s and Don’ts in Bankruptcy Cases?
        </StyledLink>
      </>
    )
  }

  return (
    <Paper
      elevation={0}
      sx={{
        color: '#777',
        border: '2px solid green',
        p: 2,
        pb: 3,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Avatar variant='square'>LT</Avatar>
      <Stack spacing={1.5} px={2}>
        <Typography variant='subtitle2' color='textSecondary'>
          Hello! Heard that you’re killing it in the courtroom. Need assistance? Ask and you shall
          receive.
        </Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          You can try the following prompts:
        </Typography>

        {/* <StyledLink>How to deal with a Civil Case?</StyledLink>
        <StyledLink>What are the Do’s and Don’ts in Civil Cases?</StyledLink> */}
        {content}
      </Stack>
    </Paper>
  )
}

export default NewMessage

import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { MicIcon } from '../../../../components/Icons'

const MicPhoneButton = () => {
  const { listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()
  const isPaid = sessionStorage.getItem('isPaid')

  const [disableMic, setDisableMic] = useState<boolean>(false)

  useEffect(() => {
    if (!isPaid) {
      setDisableMic(true)
    }
  }, [])

  const handleMicClick = () => {
    if (listening) {
      SpeechRecognition.stopListening()
      resetTranscript()
    } else {
      SpeechRecognition.startListening()
    }
  }

  return (
    <InputAdornment position='end' sx={{ pr: 11 }}>
      <IconButton
        disabled={!browserSupportsSpeechRecognition || listening}
        onClick={handleMicClick}
      >
        {/* {listening && <MicIcon strokeColor={listening ? 'blue' : 'red'} />}
        {!listening && <MicIcon strokeColor='red' />} */}
        <MicIcon strokeColor={listening ? 'blue' : 'red'} />
      </IconButton>
    </InputAdornment>
  )
}

export default MicPhoneButton

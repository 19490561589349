import React, { type ChangeEvent, useState, useEffect, useRef, useContext } from 'react'
import { useSpeechRecognition } from 'react-speech-recognition'
import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  InputBase,
  type InputBaseProps,
  styled,
  Alert,
  Snackbar,
  IconButton, Tooltip,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MicPhoneButton from './MicPhoneButton'
import InputMessage from './InputMessage'
import { CloseIcon, SendIcon } from '../../../../components/Icons'
import ResponseButton from './ResponseButton'
import { useSidebarContext } from '../../../../contexts'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SContext from '../../../../contexts/SidebarContext'
import { toast } from 'react-toastify'
import baseUrl from '../../../../config/baseUrl'

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderWidth: 0.5,
  borderColor: theme.palette.text.primary,
  '& .MuiInputAdornment-positionEnd': {
    paddingRight: theme.spacing(1.5),
  },
}))

interface Props {
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
  setAnswer: React.Dispatch<React.SetStateAction<string>>
  aiStorage: any[] // Assuming aiStorage is an array of objects
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
}
const SendMessage = ({ setInputMessage, setAnswer, aiStorage, setAiStorage }: Props) => {
  const { transcript } = useSpeechRecognition()
  const hasMessageValue = false
  // const [message, setMessage] = useState<string>('')
  const {
    userStatus,
    handleSearchCount,
    isLoading,
    setIsLoading,
    inputMessage,
    sendData,
    message,
    setMessage,
    lastAskedQuestion,
    setLastAskedQuestion,
    setIsResponseComplete,
  } = useSidebarContext()

  const {
    isRefFileUploaded,
    setIsRefFileUploaded,
    uploadedFileName,
    setUploadedFileName,
    uniqueString,
    setUniqueString,
  } = useContext(SContext)

  const promptRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [uploadStatus, setUploadStatus] = useState('')

  const token = localStorage.getItem('token')
  const [inputQuestion, setInputQuestion] = useState<string>('')
  const [sendIsLoading, setSendIsLoading] = useState(false)
  const [isSendBtnClicked, setIsSendBtnClicked] = useState(false)
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const navigate = useNavigate()

  useEffect(() => {
    console.log('input message ', inputMessage)
    if (inputMessage?.length > 0 && promptRef.current !== null) {
      promptRef.current.value = inputMessage
      setInputQuestion(inputMessage)
    } else {
      setInputQuestion('')
    }
  }, [inputMessage])

  useEffect(() => {
    if (transcript?.length > 0) {
      setMessage(transcript)
      setLastAskedQuestion(transcript)
      setInputQuestion('')
    }
  }, [transcript])

  useEffect(() => {
    selectedFile && onFileUpload()
  }, [selectedFile])

  useEffect(() => {
    // message.length > 0 && setInputQuestion(message)
    if (isSendBtnClicked && promptRef?.current !== null) {
      promptRef.current.value = ''
    }
    message.length > 0 &&
      setTimeout(() => {
        setSendIsLoading(false)
        setIsSendBtnClicked(false)
      }, 1000)
  }, [message])

  const isPaid: string = sessionStorage.getItem('isPaid') ?? ''

  const userId: string = localStorage.getItem('userId') ?? ''

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleChange = (event: any) => {
    const { value: inputValue } = event.target

    setInputQuestion(inputValue)
    // setMessage(inputValue)
    // setLastAskedQuestion(inputValue)
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()

      // if (inputQuestion.length !== 0) {
      //   // alert('inside handleSendClick')
      //   setIsLoading(true)
      //   const uStatus = await userStatus(userId)
      //   const promptValue = promptRef.current?.value ?? ''
      //   uStatus?.status && handleSearchCount(promptValue, userId)
      //   // setMessage('')
      // }

      // console.log('Enter pressed:', event.key) // Example action
    }
  }

  const handleSendClick = async () => {
    setIsSendBtnClicked(true)
    if (!userId) {
      setSnackbarMessage('Your session has expired please login again')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setTimeout(() => {
        navigate('/')
      }, 3500)
    } else if (promptRef?.current?.value?.length === 0) {
      setSnackbarMessage('Input prompt cannot be empty, Enter something!')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    } else {
      // alert('inside handleSendClick')
      setSendIsLoading(true)
      const uStatus: any = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user status!')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
      } else {
        const promptValue = promptRef.current?.value ?? ''
        uStatus?.status && handleSearchCount(promptValue, userId)
      }
    }

    // setMessage('')
  }

  const handleAttachFile = () => {
    fileInputRef.current?.click()
  }


  const handleUnloadFile = () => {
    setSendIsLoading(true)

    setIsRefFileUploaded(false)
    setUploadedFileName('')
    setUniqueString('')

    setSnackbarMessage('File removed!')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)

    setSendIsLoading(false)
  }


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      console.log('Selected file:', file)
      setSelectedFile(file)
      setUploadedFileName(file.name)
      // You can perform file upload or other actions here
    }
  }

  const onFileUpload = () => {
    setSendIsLoading(true)
    if (!selectedFile) {
      setUploadStatus('Please select a file first!')
      setSnackbarMessage('Please select a file first!')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
      setSendIsLoading(false)
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    const generatedUniqueString = generateUniqueString()
    if (generatedUniqueString) {
      setUniqueString(generatedUniqueString) // Store the unique string in state
      formData.append('uniqueString', generatedUniqueString) // Add the unique string to the form data

      fetch('http://65.0.32.48:5000/pyapi/mainqa', {
        method: 'POST',
        headers: {
          usecase: 'upload',
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((err) => {
              throw new Error(err.error)
            })
          }
          return response.json()
        })
        .then((data) => {
          console.log('after upload file:', data)
          // console.log('File successfullly uploaded')
          setUploadStatus('File successfully uploaded!')
          setSnackbarMessage('File successfully uploaded!')
          setSnackbarSeverity('success')
          setSnackbarOpen(true)
          setSendIsLoading(false)
          setIsRefFileUploaded(true)
        })
        .catch((error) => {
          // console.log(`Error: ${error.message}`)
          setUploadStatus(error?.message)
          setSnackbarMessage('File successfully uploaded!')
          setSnackbarSeverity('error')
          setSnackbarOpen(true)
          setSendIsLoading(false)
        })
    }

    // axios
    //   .post('http://localhost:5000/mainqa', formData, {
    //     headers: { usecase: 'upload' },
    //   })
    //   .then((response) => {
    //     setUploadStatus('File successfully uploaded!')
    //   })
    //   .catch((error) => {
    //     setUploadStatus(`Error: ${error.response.data.error}`)
    //   })
  }

  const generateUniqueString = () => {
    return 'xxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  return (
    <>
      <Stack
        spacing={1}
        position='sticky'
        bottom={0}
        zIndex='1100'
        sx={{
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#F8F8F8 54.73%)',
        }}
      >
        <Stack direction='row' alignItems='flex-end' flexGrow={1} spacing={2}>
          <Stack flexGrow={1}>
            <ResponseButton />
            {/* <InputMessage
            inputQuestion={inputQuestion}
            onChange={handleChange}
            onKeyDown={(event) => {
              handleEnterKeyPress(event, setInputQuestion)
            }}
          /> */}
            {isRefFileUploaded && (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <AttachFileIcon sx={{ fontSize: '0.75rem' }} />
                <Typography variant='link' fontWeight='regular' color='navy'>
                  File uploaded : {uploadedFileName}
                </Typography>

                <Tooltip title="Remove File" placement='top'>
                  <IconButton sx={{ ml: '.75rem' }} onClick={handleUnloadFile}>
                    <CloseIcon style={{ color: 'red', fontSize: '0.75rem' }} />
                  </IconButton>
                </Tooltip>
              </span>
            )}
            <StyledInputBase
              inputRef={promptRef}
              multiline
              maxRows={4}
              // value={inputQuestion}
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress}
              placeholder='Enter a prompt here'
              // endAdornment={isPaid === 'true' ? <MicPhoneButton /> : null}
              endAdornment={<MicPhoneButton />}
              startAdornment={
                <IconButton onClick={handleAttachFile}>
                  <AttachFileIcon />
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </IconButton>
              }
            />
          </Stack>
          <Button
            variant='contained'
            disabled={promptRef?.current?.value?.length === 0}
            sx={{ minWidth: 50, width: 50, height: 50, p: 0 }}
            onClick={() => {
              handleSendClick() // Optionally, call handleSendClick as well
            }}
          >
            {sendIsLoading ? <CircularProgress color='warning' /> : <SendIcon />}
          </Button>
        </Stack>
        <Typography variant='link' fontWeight='regular'>
          <Typography variant='link' color='error.main'>
            *
          </Typography>{' '}
          This platform may produce inaccurate information, that doesn’t represent Law Tech views
        </Typography>
      </Stack >
      {snackbarOpen && (
        <Snackbar
          sx={{ zIndex: '10002' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )
      }
    </>
  )
}

export default SendMessage

import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'
import { Outlet, useNavigate } from 'react-router-dom'

import baseUrl from '../config/baseUrl'
function HomeLayout() {
  const navigate = useNavigate()

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  const token = sessionStorage.getItem('token')

  useEffect(() => {
    const fetchIpAndStore = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await fetch('https://api.ipify.org?format=json')
        const ipData = await ipResponse.json()
        console.log('ipData', ipData)
        const ip = ipData?.ip

        // Send the IP address to the backend
        await fetch(`${baseUrl}/api/admin/storeIpAddress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddress: ip }),
        })
      } catch (error) {
        console.error('Error fetching/storing IP address:', error)
      }
    }
    fetchIpAndStore()
  }, [])

  if (token === undefined) {
    navigate('/')
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <>
      <div>
        <Outlet />
      </div>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            elevation={6}
            variant='filled'
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default HomeLayout
